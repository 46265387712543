<template>
  <v-dialog
    v-model="$store.state.app.show_session_expiration_dialog"
    width="450"
    persistent
    content-class="session-dialog"
  >
    <v-card rounded="lg" elevation="8" class="session-card">
      <v-card-title class="session-header primary white--text">
        <v-icon left color="white">mdi-account-alert</v-icon>
        Session expired
      </v-card-title>

      <v-card-text class="session-content pa-6">
        <v-alert
          type="warning"
          border="left"
          colored-border
          elevation="2"
          class="mb-4"
          dense
        >
          <v-icon left>mdi-information</v-icon>
          Your session has expired
        </v-alert>
        
        <p class="text-body-1">
          This session expired as you logged in with another account. Please refresh the page to continue.
        </p>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="pa-4">
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          rounded
          elevation="2"
          @click="refresh"
          class="px-6"
        >
          <v-icon left>mdi-refresh</v-icon>
          Refresh page
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {
    
  },
  data(){
    return {
      dialog: true
    }
  },
  computed: {
    
  },
  methods: {
    refresh(){
      location.reload()
    }
  },
  mounted(){
    
  }
}
</script>

<style scoped>
.session-dialog {
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.session-card {
  overflow: hidden;
  animation: fadeIn 0.3s ease-out;
}

.session-header {
  padding: 16px 20px;
}

.session-content {
  padding: 24px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
